import { Box, Typography, useTheme } from '@mui/material';
import format from 'date-fns/format';
import React from 'react';
import { Tooltip } from '../../../../../../../common/components/Tooltip/Tooltip';
import { useTypedSelector } from '../../../../../../../store';

const getPercentValue = (part: number, total: number) => {
  if (!part || !total) return `0%`;

  return `${Math.round((part / total) * 100)}%`;
};

export const InvitationStats: React.FC = () => {
  const theme = useTheme();

  const lastSendBatchStats = useTypedSelector((state) => state.campaigns.lastSendBatchStats);

  if (!lastSendBatchStats) return <></>;

  const clickThroughPercent = getPercentValue(
    lastSendBatchStats.stats.visitors,
    lastSendBatchStats.stats.invitesDelivered,
  );

  const conversionPercent = getPercentValue(
    lastSendBatchStats.stats.videos,
    lastSendBatchStats.stats.visitors,
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '4px',
          background: '#F0EEFF',
          padding: '8px',
          marginBottom: '16px',
        }}
      >
        <Typography sx={{ color: '#475467', fontSize: '20px', fontWeight: '600' }}>
          {format(new Date(), 'MMM dd, yyyy')} - Performance Summary
        </Typography>
        <Box
          sx={{
            marginLeft: '-4px',
            marginTop: '6px',
          }}
        >
          <Tooltip
            maxWidth="100%"
            content={
              <Box sx={{ textWrap: 'nowrap', fontSize: '12px', color: '#667085' }}>
                {[
                  {
                    title: 'Visitors',
                    description: 'Customer clicked link to access Creator App',
                  },
                  {
                    title: 'Click-through',
                    description: '(Visitors to Creator App / Delivered Invites) x 100%',
                  },
                  {
                    title: 'Conversion',
                    description: '(Count of Video Clips / Total Visitors) x 100%',
                  },
                  { title: 'Opt-outs', description: 'Customer replied STOP' },
                ].map(({ title, description }) => {
                  return (
                    <Box key={title}>
                      <b>{title}:</b> {description}
                    </Box>
                  );
                })}
              </Box>
            }
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex' }}>
        {[
          { title: 'Invites Sent', value: lastSendBatchStats.stats.invitesSent },
          { title: 'Visitors', value: lastSendBatchStats.stats.visitors },
          { title: 'Click-through', value: clickThroughPercent },
          { title: 'Video Clips', value: lastSendBatchStats.stats.videos },
          { title: 'Conversion', value: conversionPercent },
          { title: 'Opt-outs', value: lastSendBatchStats.stats.optOuts },
        ].map(({ title, value }, index) => {
          return (
            <React.Fragment key={title}>
              <Box
                sx={{
                  width: '100%',
                  padding: '10px',
                  borderLeft: index !== 0 ? '1px solid #989898' : undefined,
                }}
              >
                <Typography
                  sx={{
                    color: '#8A8A8A',
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '100%',
                    textAlign: 'center',
                    marginBottom: '14px',
                    textWrap: 'nowrap',
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    color: '#475467',
                    fontSize: '34px',
                    fontWeight: '700',
                    lineHeight: '100%',
                    textAlign: 'center',
                  }}
                >
                  {value}
                </Typography>
              </Box>
            </React.Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
