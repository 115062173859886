import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '24px',
    marginBottom: '10px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  input: {},
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
  },
}));
