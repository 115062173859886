import { FC, useEffect, useRef, useState } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  Typography,
  TextField,
  Box,
} from '@mui/material';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

import { ErrorsStrings, SingUpPage } from '../../localization/en';

import { useStyles } from './CustomSelect.style';
import { TextFieldProps } from '../TextField/TextField';

interface Option {
  value: string | number;
  content: string | number;
}

interface Props {
  name: string;
  value: string;
  onChange: (e: string | SelectChangeEvent<string>) => void;
  label?: string;
  labelInput?: string;
  options: Array<Option>;
  isRequired?: boolean;
  className?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  optional?: boolean;
  error?: string;
  state?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  height?: string;
  margin?: string;
  autoComplete?: TextFieldProps['autoComplete'];
}

export const CustomSelectWithTextField: FC<Props> = ({
  name,
  onChange,
  value,
  label,
  labelInput,
  options,
  isRequired,
  className,
  setFieldValue,
  optional = false,
  error,
  state = false,
  onBlur,
  height,
  margin,
  autoComplete = 'none',
}) => {
  const classes = useStyles({
    multiline: false /* multiline support not implemented */,
  });
  const [localChange, setlocalChange] = useState('');
  const input1Ref = useRef<HTMLInputElement>(null);
  const [otherValue, setotherValue] = useState('');
  const [text, settext] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const input = document.getElementById(label as string);
    settext(input);
    localChange === 'Other' || (localChange === 'Yes' && input && input.focus());
  }, [localChange, label]);

  useEffect(() => {
    if (localChange === 'Other' || localChange === 'Yes') {
      setFieldValue && setFieldValue(name, otherValue);
    } else {
      setFieldValue && setFieldValue(name, localChange);
    }
  }, [localChange, otherValue, onChange, name, setFieldValue]);

  useEffect(() => {
    setlocalChange(value);
  }, [value]);

  return (
    <FormControl required={isRequired} className={className} error={!!error} onBlur={onBlur}>
      {label && (
        <Typography
          className={classes.label}
          onClick={() => {
            input1Ref.current?.focus();
            text && text.focus();
          }}
        >
          {label}
          {optional ? (
            <span style={{ marginLeft: '0.5em', fontSize: '0.8em' }}>(optional)</span>
          ) : (
            ''
          )}
        </Typography>
      )}
      <Box display="flex" width="100%">
        <Select
          name={name}
          value={localChange}
          label={labelInput}
          onChange={(e) => {
            setlocalChange(e.target.value);
            if (localChange === 'Other' || localChange === 'Yes') {
              text && text.focus();
            }
          }}
          sx={{
            width: localChange === 'Other' || localChange === 'Yes' ? '25%' : '100%',
            margin: margin || '0 10px 0 0',
            height: height ? height : 'auto',
          }}
          renderValue={
            state ? (selected) => (selected.length === 0 ? 'State' : selected) : undefined
          }
          displayEmpty
          autoComplete={autoComplete}
        >
          <MenuItem disabled value="">
            State
          </MenuItem>
          {options?.map((opt) => {
            return (
              <MenuItem value={opt.value} key={opt.value}>
                {opt.content}
              </MenuItem>
            );
          })}
        </Select>

        <TextField
          className={classes.TextField}
          id={label}
          autoFocus={localChange === 'Other' || localChange === 'Yes' ? true : false}
          value={value}
          onChange={(e) => {
            setotherValue(e.target.value);
          }}
          sx={{
            width: localChange === 'Other' || localChange === 'Yes' ? 'calc(72% - 10px)' : '0px',
            opacity: localChange === 'Other' || localChange === 'Yes' ? 1 : 0,
          }}
          // required
          label={label === SingUpPage.HeardAboutUs ? SingUpPage.TellUsHow : SingUpPage.TellUsWhich}
          autoComplete={autoComplete}
        >
          {otherValue}
        </TextField>
      </Box>
      {isRequired && <FormHelperText>{ErrorsStrings.FieldIsRequired}</FormHelperText>}
      {error && (
        <FormHelperText id="input-meta-title-helper-text" error sx={{ ml: '0px' }}>
          <ReportProblemRoundedIcon color="error" style={{ height: '16px', width: '16px' }} />
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
