import { ChangeEvent as ReactChangeEvent, FC, useState, memo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './VideoDownloadDialog.helper';
import { VideoDetailsModalStringsDv5 } from '../../../../localization/en';
import { VideoApiModel } from '../../../../../api/models/videos';
import { downloadMedia } from '../../../../../services/utilities';
import { VideoDownloadCard } from '../components/VideoDownloadCard/VideoDownloadCard';
import { TopGolfVideosApiModel } from '../../../../../api/models/topgolfVideos';
import { useTypedSelector } from '../../../../../store';
import { ClientTypes } from '../../../../../api/models/common';
import { VideoDownloadSpinner } from '../../../../assets/VideoDownloadSpinner';
import { useToasts } from 'react-toast-notifications';
import { SocialVerseEventNames } from '../../../../constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import formatDate from 'date-fns/format';
import parseISODate from 'date-fns/parseISO';
import RadioIcon from '../../../../assets/RadioIcon';
import RadioCheckedIcon from '../../../../assets/RadioCheckedIcon';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { InfoRounded } from '@mui/icons-material';

interface VideoDownloadDialogProps {
  video: VideoApiModel | TopGolfVideosApiModel;
  open: boolean;
  onClose: () => void;
}

enum VideoTypes {
  WithFilter = 'withFilter',
  WithoutFilter = 'withoutFilter',
  OriginalUpload = 'originalUpload',
}

export const VideoDownloadDialog: FC<VideoDownloadDialogProps> = memo(
  ({ onClose, open, video }) => {
    const classes = useStyles();
    const [type, setType] = useState<VideoTypes>(VideoTypes.WithFilter);
    const { trackEvent } = useTrackEvent();

    const theme = useTheme();

    const { clientType } = useTypedSelector((state) => state.venue.venue);
    const { addToast } = useToasts();
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const isTopgolf = clientType === ClientTypes.TOPGOLF;
    const assets =
      process.env.REACT_APP_BUILD_ENV === 'production' ? ',l_sv-prod-assets' : ',l_sv-dev-assets';

    const getNoFilterVideoUrl = () => {
      const parts = video.url.split(assets);
      const startPart = parts[0];
      const endPart = parts[1]?.substring(parts[1].indexOf('/v1/')) || '';
      return startPart + endPart;
    };
    const getOriginal = () => {
      const [first, second] = video.url.split('/upload/');
      if (!first || !second) return video.url;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, right] = second.split('/v1/');
      return `${first}/upload/v1/${right}`;
    };

    const noFilterVideoUrl = getNoFilterVideoUrl();
    const originalVideoUrl = getOriginal();

    // useEffect(() => {
    //   if (story.url) {
    //     const [first, second] = story.url.split('/v1/');
    //     setVideoUrl(`${first},eo_0.01/v1/${second}`);
    //   }
    // }, [story.url, setVideoUrl]);
    const handleClose = () => {
      onClose();
      trackEvent(SocialVerseEventNames.downloadVideoCancel, { videoId: video.id });
    };

    const handleVideoTypeChange = (event: ReactChangeEvent<HTMLInputElement>) => {
      setType(event.target.value as VideoTypes);
      trackEvent(SocialVerseEventNames.changeDownloadVideType, {
        videoId: video.id,
        downloadVideoType: event.target.value,
      });
    };

    const downloadFile = () => {
      if (video.url && noFilterVideoUrl) {
        trackEvent(SocialVerseEventNames.downloadVideoConfirm, {
          videoId: video.id,
          downloadVideoType: type,
        });

        const filenameDate = formatDate(parseISODate(video.createdAt), 'MMM dd, yyyy').replaceAll(
          ',',
          '',
        );
        //@ts-ignore
        const [firstName, lastName] = (video.userDisplayName || video.userName).split(' ');
        const initials = lastName ? `${firstName}_${lastName.charAt(0)}` : firstName;
        const filename = `${initials}_${video.shortcode}_${filenameDate}`.replaceAll(' ', '_');

        setShowSpinner(true);
        downloadMedia(
          type === VideoTypes.WithFilter
            ? video.url
            : type === VideoTypes.WithoutFilter
            ? noFilterVideoUrl
            : originalVideoUrl,
          filename,
        ).then(() => {
          setShowSpinner(false);
          onClose();
          addToast(VideoDetailsModalStringsDv5.DownloadVideo, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        });
      }
    };

    if (!open) {
      return null;
    }

    return (
      <Dialog
        classes={{ paper: classes.paper }}
        onClose={handleClose}
        open={open}
        fullWidth={true}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle className={classes.title} id="scroll-dialog-title">
          <Typography className={classes.titleText}>
            Select a preferred version to download:
          </Typography>
          <IconButton
            className={classes.close}
            type="button"
            aria-label="Close modal"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <RadioGroup
            className={classes.group}
            onChange={handleVideoTypeChange}
            role="group"
            aria-labelledby="video-types-group"
          >
            <Box className={classes.label}>
              <VideoDownloadCard
                url={video.url}
                isActive={type === VideoTypes.WithFilter}
                onClick={() => setType(VideoTypes.WithFilter)}
              />

              <FormControlLabel
                className={classes.radio}
                name="video-type"
                value={VideoTypes.WithFilter}
                checked={type === VideoTypes.WithFilter}
                control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}
                label={!isTopgolf ? 'With Branding' : 'Processed video'}
                sx={{
                  '& .MuiFormControlLabel-label': {
                    color: type === VideoTypes.WithFilter ? theme.palette.primary.main : '#8B89A0',
                    fontWeight: type === VideoTypes.WithFilter ? '600' : '400',
                  },
                }}
              />
            </Box>
            {!isTopgolf && (
              <Box className={classes.label}>
                <VideoDownloadCard
                  url={noFilterVideoUrl}
                  isActive={type === VideoTypes.WithoutFilter}
                  onClick={() => setType(VideoTypes.WithoutFilter)}
                />

                <FormControlLabel
                  className={classes.radio}
                  name="video-type"
                  control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}
                  value={VideoTypes.WithoutFilter}
                  checked={type === VideoTypes.WithoutFilter}
                  label="Without Branding"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      color:
                        type === VideoTypes.WithoutFilter ? theme.palette.primary.main : '#8B89A0',
                      fontWeight: type === VideoTypes.WithoutFilter ? '600' : '400',
                    },
                  }}
                />
              </Box>
            )}
            <Box className={classes.label}>
              <VideoDownloadCard
                url={originalVideoUrl}
                isActive={type === VideoTypes.OriginalUpload}
                thumbnailUrl={noFilterVideoUrl}
                onClick={() => setType(VideoTypes.OriginalUpload)}
              />

              <FormControlLabel
                className={classes.radio}
                name="video-type"
                value={VideoTypes.OriginalUpload}
                checked={type === VideoTypes.OriginalUpload}
                control={<Radio icon={<RadioIcon />} checkedIcon={<RadioCheckedIcon />} />}
                label={
                  <>
                    Original Upload{' '}
                    <Tooltip
                      title="The dimensions of original video clips recorded outside the Creator App app may differ from the preview image shown."
                      width="255px"
                      maxWidth="255px"
                      fontSize="11px"
                      fontWeight={500}
                      icon={
                        <InfoRounded color="inherit" sx={{ width: '20px', marginLeft: '7px' }} />
                      }
                    />
                  </>
                }
                sx={{
                  '& .MuiFormControlLabel-label': {
                    display: 'flex',
                    alignItems: 'center',
                    color:
                      type === VideoTypes.OriginalUpload ? theme.palette.primary.main : '#8B89A0',
                    fontWeight: '600',
                  },
                }}
              />
            </Box>
          </RadioGroup>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button
            onClick={downloadFile}
            color="primary"
            variant="contained"
            className={classes.download}
          >
            {showSpinner ? (
              <VideoDownloadSpinner
                width="25px"
                height="25px"
                viewBoxHeight="70"
                viewBoxWidth="25"
              />
            ) : (
              'Download'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    );
  },
);
