import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider as StylesThemeProvide,
} from '@mui/styles';
import { FC, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';

import { AuthHandler } from './common/components/AuthHandler';
import {
  DevRoutes,
  SuperAdminRoutes,
  UnprotectedRoutes,
  WebsiteRoutes,
  WizardCreationRoute,
} from './common/constants/routes';
import './common/localization/i18';
import store from './store';

import { Layout } from './common/layout/Layout';

import { CampaignsPage } from './pages/campaigns/Campaigns';
import { Content } from './pages/content/Content';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Heroes } from './pages/heroes/Heroes';
import { Home } from './pages/home/Home';
import { Login } from './pages/login/Login';
import { ErrorPage } from './pages/venuewizard/ErrorPage';
import { LoginWizard } from './pages/venuewizard/LoginWizard';
import { VenueWizard } from './pages/venuewizard/VenueWizard';
import { WebappSettings } from './pages/webappSettings/WebappSettings';
// import { Integrations } from './pages/integrations/Integrations';
import { Performance } from './pages/admin/performance/performance';
import { NotFound } from './pages/notFound/NotFound';
import { Rewards } from './pages/rewards/Rewards';

import { theme } from './theme';

import { AppContainer, RootContainer } from './App.style';
import { Logout } from './common/components/Logout/Logout';
import { AdminVenuWizard } from './pages/admin/adminvenuwizard/adminvenuwizard';
import { WizardFailure } from './pages/venuewizard/WizardFailure';
import { WizardSuccess } from './pages/venuewizard/WizardSuccess';
import { InitSegment } from './segment';
import { InitSentry } from './sentry';
// import { ProfilePage } from './pages/profile/ProfilePage';
import { InviteTeamMember } from './common/components/InviteTeamMember/InviteTeamMember';
import { QuickStartHandler } from './common/components/QuickStartHandler/QuickStartHandler';
import { Toast } from './common/components/Toast/Toast';
import { LayoutMode } from './common/constants/constants';
import { ResetFTEComponent } from './hooks/dev/useResetFTE';
import { AccountsPage } from './pages/accounts/AccountsPage';
import { DevIcons } from './pages/dev/DevIcons';
import { Filters } from './pages/filters/Filters';
import { IncentivesFilter } from './pages/filters/Results/Incentives';
import { UsersFilter } from './pages/filters/Results/Users';
import { VideosFilter } from './pages/filters/Results/Videos';
import { NewCampaign } from './pages/NewCampaign/NewCampaign';
import { QuickStartWelcome } from './pages/onboarding/QuickStartWelcome';
import { PlansPage } from './pages/plans/PlansPage';
import { ProfilePage } from './pages/ProfilePage/ProfilePage';
import { QuickStart } from './pages/QuickStart/QuickStart';
import { RewardCampaigns } from './pages/RewardCampaigns/RewardCampaigns';
import { IncentiveAd } from './pages/rewards/IncentiveAd/IncentiveAd';
// import { SignUp } from './pages/signUp/signUpForm/SignUp';
import { SignupSuccess } from './pages/signUp/SignupSuccess';
import { SocialVersePage } from './pages/SocialVerse/SocialVerse';
import { SocialVerseConfig } from './pages/SocialVerse/SocialVerseConfig/SocialVerseConfig';
import { Workspace } from './pages/workspace/Workspace';
import { CrispChatProvider } from './services/crisp/CrispChatProvider';
import { useWindowResize } from './services/hooks/useWindowResize';
import { isDev } from './services/utilities';
import { TrackingPixel } from './pages/webapp/TrackingPixel/TrackingPixel';
import { IdleHandler } from './common/components/IdleHandler/IdleHandler';
import { EditCampaign } from './pages/EditCampaign/EditCampaign';
import { VideoBrandingPlayback } from './pages/webappSettings/VideoBrandingPlayback/VideoBrandingPlayback';
import { WebAppCards } from './pages/webappSettings/WebAppCards/WebAppCards';
import { QuickSignup } from './pages/signUp/signUpForm/QuickSignup';
import { QuickSignupSuccess } from './pages/signUp/QuickSignupSuccess';
import { CompleteProfile } from './pages/signUp/signUpForm/CompleteProfile';
import { ToastComponent } from './common/components/ToastComponent/ToastComponent';
import SignUpTab from './pages/webappSettings/SignUpTab/SignUpTab';
import { ConnectedAppsPage } from './pages/connectedApps/ConnectedApps';
import { GoogleSyncRedirect } from './pages/googleSyncRedirect/GoogleSyncRedirect';
import { isUWMClient } from './utils/isUWMClient';
import { UwmSessionTimeout } from './pages/UwmSessionTimeout/UwmSessionTimeout';
import { QuickStartLaunchPadModal } from './pages/QuickStart/QuickStartLaunchPadModal/QuickStartLaunchPadModal';
import { QSNavBar } from './common/components/QSNavBar/QSNavBar';
import { MenuResizeHandler } from './common/components/MenuResizeHandler/MenuResizeHandler';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

InitSentry();
InitSegment();

declare global {
  interface Window {
    $crisp?: unknown[];
    CRISP_WEBSITE_ID?: string;
    grecaptcha: any;
  }
}
const generateClassName = createGenerateClassName({
  seed: 'sv-dashboard',
});

const App: FC = () => {
  const deviceType = useWindowResize(true);
  const isDesktop = deviceType === LayoutMode.Desktop;

  const setBodyWidth = (width: string) => {
    window.document.body.style.width = width;
  };

  const isQuickSignupFlow =
    window.location.pathname.includes('quick-signup') ||
    window.location.pathname.includes('signup-form') ||
    window.location.pathname.includes('complete-profile');

  const isClipsSection = window.location.pathname.includes('content/all');

  useEffect(() => {
    if (isDesktop || isQuickSignupFlow || isClipsSection) {
      setBodyWidth('Auto');
    } else {
      setBodyWidth('1250px');
    }
  }, [isDesktop, isQuickSignupFlow, isClipsSection]);

  useEffect(() => {
    if (isUWMClient()) {
      document.title = 'Brand360 Video Review Manager - in partnership with SocialV';
    }
  }, []);

  return (
    <Provider store={store}>
      <StylesProvider generateClassName={generateClassName} injectFirst>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <StylesThemeProvide theme={theme}>
              <CssBaseline />
              <ToastProvider
                autoDismiss
                autoDismissTimeout={3500}
                components={{ Toast: ToastComponent }}
              >
                <Router>
                  <IdleHandler>
                    <CrispChatProvider>
                      <QuickStartHandler>
                        <AuthHandler>
                          <RootContainer>
                            <AppContainer>
                              <QSNavBar />
                              <QuickStartLaunchPadModal />
                              <MenuResizeHandler />
                              <Switch>
                                <Route exact path="/">
                                  <Home />
                                </Route>
                                <Route exact path={WebsiteRoutes.DashboardHeroes}>
                                  <Layout>
                                    <Dashboard />
                                  </Layout>
                                </Route>

                                <Route exact path={WizardCreationRoute.WizardFailure}>
                                  <WizardFailure />
                                </Route>
                                <Route exact path={UnprotectedRoutes.SignUp}>
                                  <QuickSignup />
                                </Route>
                                <Route exact path={UnprotectedRoutes.QuickSignup}>
                                  <QuickSignup />
                                </Route>
                                <Route exact path={UnprotectedRoutes.SignupSuccess}>
                                  <SignupSuccess />
                                </Route>
                                <Route exact path={UnprotectedRoutes.QuickSignupSuccess}>
                                  <QuickSignupSuccess />
                                </Route>
                                <Route exact path={UnprotectedRoutes.CompleteProfile}>
                                  <CompleteProfile />
                                </Route>

                                <Route exact path={UnprotectedRoutes.Login}>
                                  <Login />
                                </Route>
                                <Route exact path={UnprotectedRoutes.Logout}>
                                  <Logout />
                                </Route>
                                <Route path={UnprotectedRoutes.SessionTimeout}>
                                  <UwmSessionTimeout />
                                </Route>
                                <Route exact path={UnprotectedRoutes.CreateVenue}>
                                  <LoginWizard />
                                </Route>
                                <Route exact path={UnprotectedRoutes.UnAuthorized}>
                                  <ErrorPage />
                                </Route>
                                <Route exact path={UnprotectedRoutes.ResetPassword}>
                                  <Login initialStep="forgotPasswordRequest" />
                                </Route>
                                <Route exact path={WizardCreationRoute.SetupWizard}>
                                  <VenueWizard />
                                </Route>
                                <Route exact path={WizardCreationRoute.WizardSuccess}>
                                  <WizardSuccess />
                                </Route>
                                <Route exact path={WebsiteRoutes.Filters}>
                                  <Filters />
                                </Route>
                                <Route path={`${WebsiteRoutes.UsersFilterParamDetails}?`}>
                                  <UsersFilter />
                                </Route>
                                <Route path={`${WebsiteRoutes.VideosFilterParamDetails}?`}>
                                  <VideosFilter />
                                </Route>
                                <Route path={WebsiteRoutes.IncentivesFilter}>
                                  <IncentivesFilter />
                                </Route>
                                <Route exact path={WebsiteRoutes.IncentiveAd}>
                                  <Layout>
                                    <IncentiveAd />
                                  </Layout>
                                </Route>
                                <Route exact path={WebsiteRoutes.IncentiveAd}>
                                  <Layout>
                                    <IncentiveAd />
                                  </Layout>
                                </Route>

                                <Route exact path={WebsiteRoutes.Google}>
                                  <Layout>
                                    <ConnectedAppsPage />
                                  </Layout>
                                </Route>
                                <Route exact path={WebsiteRoutes.GoogleSyncRedirect}>
                                  <Layout>
                                    <GoogleSyncRedirect />
                                  </Layout>
                                </Route>
                                <Route path="/accounts/distributions">
                                  {/* make sure old route is redirected to new page location */}
                                  <Redirect to={WebsiteRoutes.Google} />
                                </Route>

                                <Route
                                  path={[
                                    WebsiteRoutes.SocialVerse,
                                    `${WebsiteRoutes.SocialVerseAddVideos}/:id?`,
                                    `${WebsiteRoutes.SocialVerseSettings}/:id?`,
                                    WebsiteRoutes.Dashboard,
                                    WebsiteRoutes.DashboardWidget,
                                    `${WebsiteRoutes.Campaigns}/:id?`,
                                    `${WebsiteRoutes.HeroesDetail}?`,
                                    `${WebsiteRoutes.Integrations}/:path?/:path?`,
                                    `${WebsiteRoutes.Settings}/:path?/:path?/:path?`,
                                    `${WebsiteRoutes.TeamSettingsDetail}?`,
                                    `${WebsiteRoutes.Workspace}/:path?`,
                                    `${WebsiteRoutes.Accounts}/:path?`,
                                    `${WebsiteRoutes.Profile}/:path?`,

                                    `${WebsiteRoutes.WorkspaceMembersInvite}/:path?`,
                                  ]}
                                  exact
                                >
                                  <Switch>
                                    <Route path={WebsiteRoutes.Accounts}>
                                      <Layout>
                                        <div style={{ position: 'relative' }}>
                                          <AccountsPage />
                                        </div>
                                      </Layout>
                                    </Route>
                                    <Route path={WebsiteRoutes.Profile}>
                                      <Layout>
                                        <div style={{ position: 'relative' }}>
                                          <ProfilePage />
                                        </div>
                                      </Layout>
                                    </Route>

                                    <Route path={WebsiteRoutes.Workspace}>
                                      <Layout>
                                        <div style={{ position: 'relative' }}>
                                          <Workspace />
                                        </div>
                                      </Layout>
                                    </Route>
                                    <Route exact path={WebsiteRoutes.WorkspaceMembersInvite}>
                                      <InviteTeamMember />
                                    </Route>
                                    <Route exact path={WebsiteRoutes.WorkspaceMembersEdit}>
                                      <InviteTeamMember />
                                    </Route>
                                    <Route exact path={WebsiteRoutes.DashboardWidget}>
                                      <Layout>
                                        <Dashboard />
                                      </Layout>
                                    </Route>
                                    <Route exact path={WebsiteRoutes.SocialVerse}>
                                      <Layout>
                                        <SocialVersePage />
                                      </Layout>
                                    </Route>
                                    <Route path={WebsiteRoutes.SocialVerse}>
                                      <SocialVerseConfig />
                                    </Route>
                                    <Route path={`${WebsiteRoutes.Campaigns}/:id?`}>
                                      <Layout>
                                        <CampaignsPage />
                                      </Layout>
                                    </Route>
                                    <Route path={`${WebsiteRoutes.HeroesDetail}?`}>
                                      <Layout>
                                        <Heroes />
                                      </Layout>
                                    </Route>
                                    <Route path={[WebsiteRoutes.Settings]}>
                                      <Layout>
                                        <WebappSettings />
                                      </Layout>
                                    </Route>
                                  </Switch>
                                </Route>
                                <Route path={['/', '/content/:path?/:id?']} exact>
                                  <Layout>
                                    <Switch>
                                      <Route path={WebsiteRoutes.ContentArchived + '/:id?'}>
                                        <Content />
                                      </Route>
                                      <Route path={WebsiteRoutes.ContentModerated + '/:id?'}>
                                        <Content />
                                      </Route>
                                      <Route path={WebsiteRoutes.ContentPublished + '/:id?'}>
                                        <Content />
                                      </Route>
                                      <Route path={WebsiteRoutes.ContentUnpublished + '/:id?'}>
                                        <Content />
                                      </Route>
                                      <Route path={WebsiteRoutes.ContentAll + '/:id?'}>
                                        <Content />
                                      </Route>
                                      <Route exact path={WebsiteRoutes.Content}>
                                        <Redirect to={WebsiteRoutes.ContentAll} />
                                      </Route>
                                    </Switch>
                                  </Layout>
                                </Route>
                                <Route path="/rewards/:path?/:id?" exact>
                                  <Layout>
                                    <Switch>
                                      <Route exact path={WebsiteRoutes.RewardsCampaigns}>
                                        <Rewards />
                                      </Route>
                                      <Route
                                        exact
                                        path={WebsiteRoutes.RewardsLeaderboard + '/:id?'}
                                      >
                                        <Rewards />
                                      </Route>
                                      <Route exact path={WebsiteRoutes.Rewards}>
                                        <Rewards />
                                      </Route>
                                      <Route path={WebsiteRoutes.RedeemRequests + '/:id?'}>
                                        <Rewards />
                                      </Route>
                                      <Route path={WebsiteRoutes.RewardsWizard}>
                                        <Rewards />
                                      </Route>
                                      <Route path={WebsiteRoutes.RewardsList}>
                                        <Rewards />
                                      </Route>
                                    </Switch>
                                  </Layout>
                                </Route>
                                <Route path={WebsiteRoutes.RewardCampaigns + '/:id?'}>
                                  <Layout>
                                    <Switch>
                                      <Route path={WebsiteRoutes.RewardCampaigns}>
                                        <RewardCampaigns />
                                      </Route>
                                      <Route path={WebsiteRoutes.RewardCampaigns + '/:id?'}>
                                        <RewardCampaigns />
                                      </Route>
                                    </Switch>
                                  </Layout>
                                </Route>

                                <Route path={WebsiteRoutes.RewardCampaigns + '/:id?'}>
                                  <Layout>
                                    <Switch>
                                      <Route path={WebsiteRoutes.RewardCampaigns}>
                                        <RewardCampaigns />
                                      </Route>
                                      <Route path={WebsiteRoutes.RewardCampaigns + '/:id?'}>
                                        <RewardCampaigns />
                                      </Route>
                                    </Switch>
                                  </Layout>
                                </Route>
                                <Route
                                  path={[
                                    SuperAdminRoutes.OrgManagement,
                                    SuperAdminRoutes.OrgManagementDetail,
                                    SuperAdminRoutes.AddNewVenue,
                                    SuperAdminRoutes.Performance,
                                  ]}
                                  exact
                                >
                                  <Layout>
                                    <Switch>
                                      <Route path={SuperAdminRoutes.Performance}>
                                        <Performance />
                                      </Route>
                                      <Route path={SuperAdminRoutes.AddNewVenue}>
                                        <AdminVenuWizard />
                                      </Route>
                                    </Switch>
                                  </Layout>
                                </Route>

                                <Route path={WebsiteRoutes.PricingPlans}>
                                  <PlansPage signUpRoute={UnprotectedRoutes.SignUp} />
                                </Route>

                                <Route exact path={WebsiteRoutes.QuickStart}>
                                  <Redirect to={WebsiteRoutes.QuickStartWelcome} />
                                </Route>
                                <Route exact path={WebsiteRoutes.QuickStartWelcome}>
                                  <QuickStartWelcome />
                                </Route>
                                <Route path={[`${WebsiteRoutes.QuickStart}/*`]}>
                                  <QuickStart />
                                </Route>

                                <Route exact path={[WebsiteRoutes.NewCampaign]}>
                                  <NewCampaign />
                                </Route>
                                <Route path={[`${WebsiteRoutes.NewCampaign}/*`]}>
                                  <NewCampaign />
                                </Route>
                                <Route
                                  exact
                                  path={[
                                    `${WebsiteRoutes.EditCampaign}/:id/*`,
                                    `${WebsiteRoutes.EditCampaign}/:id`,
                                  ]}
                                >
                                  <EditCampaign />
                                </Route>

                                <Layout>
                                  <Route path={WebsiteRoutes.WebAppSettings} exact>
                                    <Redirect to={WebsiteRoutes.BrandingStyleTab} />
                                  </Route>
                                  <Route path={WebsiteRoutes.BrandingStyleTab}>
                                    <WebAppCards />
                                  </Route>
                                  <Route path={WebsiteRoutes.SignUpTab}>
                                    <SignUpTab />
                                  </Route>
                                  <Route path={WebsiteRoutes.VideoPlaybackTab}>
                                    <VideoBrandingPlayback />
                                  </Route>
                                  <Route path={WebsiteRoutes.QRCode}>
                                    <Redirect to={WebsiteRoutes.BrandingStyleTab} />
                                  </Route>
                                  <Route path={WebsiteRoutes.TrackingPixelTab}>
                                    <TrackingPixel />
                                  </Route>
                                  {/* sign up page was removed SV-367 Update dashboard video app section */}
                                  {/* <Route path={WebsiteRoutes.SignUpTab}>
                                    <SignUpTab />
                                  </Route> */}
                                  {/* </Layout> */}

                                  {/* dev routes */}
                                  {isDev && (
                                    <>
                                      <Route path={DevRoutes.ResetFTE}>
                                        <ResetFTEComponent />
                                      </Route>
                                      <Route exact path={DevRoutes.DevIcons}>
                                        <DevIcons />
                                      </Route>
                                    </>
                                  )}
                                </Layout>
                                <Route path="*" component={NotFound} />
                              </Switch>
                            </AppContainer>
                          </RootContainer>
                        </AuthHandler>
                      </QuickStartHandler>
                    </CrispChatProvider>
                  </IdleHandler>
                </Router>
                <Toast />
              </ToastProvider>
            </StylesThemeProvide>
          </ThemeProvider>
        </StyledEngineProvider>
      </StylesProvider>
    </Provider>
  );
};

export default App;
